<template>
	<edit-template style="min-width: 1450rem" @confirm="submitForm('ruleForm')" @cancel="onBack" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm form-body" label-width="140rem" style="padding-top: 70rem;padding-left: 48rem">
			<el-form-item label="续费学年" prop="year">
				<el-select v-model="ruleForm.year" placeholder="请选择续费学年">
					<el-option v-for="n in 3" :key="n" :label="new Date().getFullYear() + n - 1" :value="new Date().getFullYear() + n - 1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="应用校区" prop="school_id">
				<el-select v-model="ruleForm.school_id" placeholder="请选择应用校区">
					<el-option v-for="(item,key) in campus" :key="key" :label="item.school_name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="应用年级" prop="grade_id">
				<el-select v-model="ruleForm.grade_id" placeholder="请选择应用年级">
					<el-option v-for="(item,key) in grade" :key="key" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="开始续费时间" prop="start_pay_time">
				<el-date-picker v-model="ruleForm.start_pay_time" format="yyyy-MM-dd HH:mm" placeholder="请选择开始续费时间" type="datetime" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
			</el-form-item>
			<el-form-item label="结束续费时间" prop="end_pay_time">
				<el-date-picker v-model="ruleForm.end_pay_time" format="yyyy-MM-dd HH:mm" placeholder="请选择结束续费时间" type="datetime" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
			</el-form-item>
			<!--			<el-form-item label='购买商品套餐' prop='goods_pack_name_id' style='position: relative'>-->
			<!--				<el-select v-model='ruleForm.goods_pack_name_id' placeholder='请选择购买商品套餐'>-->
			<!--					<el-option v-for='(item,key) in packageCombination' :key="key" :label='item.pack_name' :value='item.goods_pack_name_id'></el-option>-->
			<!--				</el-select>-->
			<!--			</el-form-item>-->
			<!--			<el-form-item label='购买商品规格' prop='goods_pack_combine_id' style='position: relative'>-->
			<!--				<el-select v-model='ruleForm.goods_pack_combine_id' placeholder='请选择购买商品规格'>-->
			<!--					<el-option v-for='(item,key) in productSpecifications' :key="key" :label='item.standard_name' :value='item.goods_pack_combine_id'></el-option>-->
			<!--				</el-select>-->
			<!--			</el-form-item>-->
			<el-form-item label="备注">
				<el-input v-model="ruleForm.remark" :rows="8" maxlength="300" placeholder="请输入备注" show-word-limit="" style="width: 374rem" type="textarea"></el-input>
			</el-form-item>
		</el-form>
		
	
</edit-template>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  name: 'add',
	data() {
		// 这里存放数据
		return {
			ruleForm: {
				year: '', // 续费学年
				school_id: '', // 应用校区ID
				grade_id: '', // 应用年级ID
				start_pay_time: '', // 开始续费时间
				end_pay_time: '', // 结束续费时间
				goods_pack_name_id: '', // 购买商品套餐
				goods_pack_combine_id: '', // 购买商品组合id
				remark: '', // 备注
			},
			rules: {
				year: [
					{required: true, message: '请选择续费学年', trigger: 'change'},
				],
				school_id: [
					{required: true, message: '请选择应用校区', trigger: 'change'},
				],
				grade_id: [
					{required: true, message: '请选择应用年级', trigger: 'change'},
				],
				start_pay_time: [
					{required: true, message: '请选择开始续费时间', trigger: 'change'},
				],
				end_pay_time: [
					{required: true, message: '请选择结束续费时间', trigger: 'change'},
				],
				goods_pack_name_id: [
					{required: true, message: '请选择购买商品套餐', trigger: 'change'},
				],
				goods_pack_combine_id: [
					{required: true, message: '请选择购买商品规格', trigger: 'change'},
				],
				remark: [
					{required: true, message: '请输入备注', trigger: 'change'},
				],
			},
			packageCombination: [], // 套餐组合
			campus: [], // 校区
			grade: [], // 年级
			productSpecifications: [], // s商品规格
		};
	},
	watch: {
		ruleForm: {
			handler(val) {
				if (val.school_id && val.goods_pack_name_id) {
					this.$_register.get('api/recruit/continue-pay/goods-standard', {params: {school_id: val.school_id, goods_pack_name_id: val.goods_pack_name_id}}).then(res => {
						this.productSpecifications = res.data.data
					})
				}
			},
			deep: true
		}
	},
	// 方法集合
	methods: {


		onBack() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$router.back();
			});
		},

		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$_register.post('api/recruit/continue-pay/add', {...this.ruleForm}).then(res => {
						if (res.data.status === 0) {
							this.$message.success('新增成功')
							this.$store.commit('setPage', 1)
							this.$router.back()
						}
					})
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
	},
	// 生命周期 - 创建完成（可以访问当前this 实例）
	created() {
		// this.$_register.get('api/recruit/continue-pay/goods-pack').then(res => {
		// 	this.packageCombination = res.data.data
		// 	console.log(res.data.data)
		// })
		// 获取学校
		this.$_register.get('api/recruit/common/consulting-school').then(res => {
			this.campus = res.data.data.entity_school
		})
		// h获取年级
		this.$_register.get('api/recruit/common/grades').then(res => {
			this.grade = res.data.data
		})
	},
};
</script>

<style scoped>
</style>
